import { inject } from '@angular/core';
import { createInjectable } from 'ngxtension/create-injectable';
import { signalSlice } from 'ngxtension/signal-slice';
import { catchError, distinctUntilChanged, EMPTY, filter, map, switchMap, tap } from 'rxjs';
import type { UserContextGetResponseType } from '@tig-dpqa-cloud/contract-backend-frontend';
import { HttpClient } from '@angular/common/http';
import { TenantService } from '../tenant/tenant.service';
import { StorageService } from '../storage/storage.service';

export const UserContextService = createInjectable(
  () => {
    const http = inject(HttpClient);
    const tenantService = inject(TenantService);
    const storageService = inject(StorageService);

    const initialState: {
      userPermissions: UserContextGetResponseType['user']['permissions'] | undefined;
      tenantConfig: UserContextGetResponseType['tenant'] | undefined;
      userProfile: Omit<UserContextGetResponseType['user'], 'permissions'> | undefined;
      dataplaneVersion: UserContextGetResponseType['dataplaneVersion'];
      possibleTenants: UserContextGetResponseType['possibleTenants'];
      isTenantOnboarded: UserContextGetResponseType['isTenantOnboarded'];
      credentials: UserContextGetResponseType['credentials'] | undefined;
      couldUpgradeDataplane: UserContextGetResponseType['couldUpgradeDataplane'];
    } = {
      userProfile: undefined,
      userPermissions: undefined,
      tenantConfig: undefined,
      dataplaneVersion: undefined,
      possibleTenants: [],
      isTenantOnboarded: undefined,
      credentials: undefined,
      couldUpgradeDataplane: undefined,
    };

    const userContext$ = storageService.watch('token').pipe(
      filter(Boolean),
      distinctUntilChanged(),
      switchMap(() =>
        http.get<UserContextGetResponseType>('/api/user/context').pipe(
          map((res) => ({
            userPermissions: res.user.permissions,
            tenantConfig: res.tenant,
            userProfile: {
              firstName: res.user.firstName,
              lastName: res.user.lastName,
              email: res.user.email,
            },
            dataplaneVersion: res.dataplaneVersion,
            possibleTenants: res.possibleTenants,
            isTenantOnboarded: res.isTenantOnboarded,
            credentials: res.credentials,
            couldUpgradeDataplane: res.user.permissions.includes('DATAPLANE_CONFIGURATION_WRITE')
              ? res.couldUpgradeDataplane
              : false,
          })),
          tap(({ tenantConfig }) => (tenantService.currentTenant = tenantConfig?.tenantId)),
          catchError(() => EMPTY),
        ),
      ),
    );

    const state = signalSlice({
      initialState,
      sources: [userContext$],
    });

    return {
      userProfile: state.userProfile,
      userPermissions: state.userPermissions,
      tenantConfig: state.tenantConfig,
      dataplaneVersion: state.dataplaneVersion,
      possibleTenants: state.possibleTenants,
      isTenantOnboarded: state.isTenantOnboarded,
      credentials: state.credentials,
      couldUpgradeDataplane: state.couldUpgradeDataplane,
    };
  },
  { providedIn: 'root' },
);
